<template>
  <div>
    <div @click="updateMute()" class="mute-button">
      <Muted v-if="mute" />
      <Unmuted v-if="!mute" />
    </div>
    <Start v-if="gamePosition === 'start'"></Start>
    <Part1 v-if="gamePosition === 1"></Part1>
    <Part2 v-if="gamePosition === 2"></Part2>
    <Part3 v-if="gamePosition === 3"></Part3>
    <Part4 v-if="gamePosition === 4"></Part4>
    <End v-if="gamePosition === 'end'"></End>
    <div v-if="development" class="mt-4 container">
      <button @click="logout()" class="btn btn-primary">Logout</button>
      <button @click="saveState()" class="ml-1 btn btn-primary">
        Reset State
      </button>
      <button @click="loadState" class="ml-1 btn btn-primary">
        Load state
      </button>
    </div>
  </div>
</template>

<script>
import { loadState, logOut, saveState } from "@/firebase";
import Muted from "@/assets/muted.svg?inline";
import Unmuted from "@/assets/unmuted.svg?inline";
import Start from "@/components/Start";
import Part1 from "@/components/Part1";
import Part2 from "@/components/Part2";
import Part3 from "@/components/Part3";
import Part4 from "@/components/Part4";
import End from "@/components/End";
import { Howler } from "howler";
import { DEFAULT_VOLUME } from "../utils";

export default {
  name: "Game",
  components: { End, Start, Part1, Part2, Part3, Part4, Muted, Unmuted },
  data() {
    return {
      mute: false,
    };
  },
  mounted() {
    // Sets the global volume.
    Howler.volume(DEFAULT_VOLUME);
  },
  computed: {
    gamePosition() {
      return this.$store.state.game.position;
    },
    development() {
      return process.env.NODE_ENV === "development";
    },
  },
  methods: {
    updateMute() {
      this.mute = !this.mute;
    },
    async logout() {
      await logOut();
      await this.$router.push("/login");
    },
    async saveState() {
      await saveState({
        current_section: 1,
      });
      await this.loadState();
      await this.$store.commit("setPosition", "start");
    },
    async loadState() {
      const response = await loadState();
      if (!response.data.error)
        this.$store.commit("setSaveData", response.data.state);
      else console.error(response.data.message);
    },
  },
  watch: {
    mute: {
      handler(newValue) {
        Howler.mute(newValue);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.mute-button {
  position: fixed;
  top: 50px;
  left: 1rem;
  width: 40px;
  height: 40px;
  z-index: 9999;
  cursor: pointer;
  @media screen and (max-width: 640px) {
    top: 25px;
    width: 35px;
    height: 35px;
  }
  svg {
    color: #fff;
  }
}
</style>
