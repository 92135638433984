<template>
  <section class="end">
    <NewsWrapper v-if="news">
      <template v-slot:news-content>
        <Videoplayer v-if="video" v-on:videoEnded="allowEnd" :options="videoOptions" class="video-container"/>
        <div v-else-if="headline" class="news">
          <div class="offset-1 col-10 col-lg-8 news-content">
            <h1>{{ results.text }}</h1>
            <p>{{ results.casualties }}</p>
          </div>
        </div>
      </template>
      <template v-if="video" v-slot:news-buttons>
        <div class="btn-container text-center">
          <button disabled @click="videoEnd" id="video-continue" class="btn btn-news">JATKA</button>
        </div>
      </template>
      <template v-else-if="headline" v-slot:news-buttons>
        <div class="btn-container text-center">
          <button @click="goToStats" class="btn btn-news">JATKA</button>
        </div>
      </template>
    </NewsWrapper>
    <StartWrapper v-else-if="stats">
      <template v-slot:start-content>
        <Statistics class="statistics" :results="results"></Statistics>
      </template>
      <template v-slot:start-buttons>
        <div class="row">
          <div class="col-md-6 text-center text-md-right">
            <a href="mailto:box@exite.fi">
              <button class="btn btn-news">Anna palautetta</button>
            </a>
          </div>
          <div class="col-md-6 text-center text-md-left">
            <button @click="logout" class="btn btn-news">Palaa etusivulle</button>
          </div>
        </div>
      </template>
    </StartWrapper>
  </section>
</template>

<script>
import TaysPelastus from "@/assets/video/01-Ei Osumaa-1.mp4";
import Tsunami from "@/assets/video/02-Pienituho-1.mp4";
import PuoliTuho from "@/assets/video/03-Puolituho-1.mp4";
import TaysTuho from "@/assets/video/04-Taystuho-1.mp4";
import Videoplayer from "@/components/partials/Videoplayer";
import { Results } from '@/results';
import Statistics from "@/components/partials/Statistics";
import {loadState, logOut, saveState} from "@/firebase";
import NewsWrapper from "@/components/partials/NewsWrapper";
import StartWrapper from "@/components/partials/StartWrapper";

export default {
name: "End",
  components: {StartWrapper, NewsWrapper, Statistics, Videoplayer},
  data() {
    return {
      password: true,
      video: true,
      headline: false,
      news: true,
      stats: false,
      videoOptions: {
        autoplay: true,
        controls: true,
        fluid: true,
        sources: [
          {
            src: TaysTuho,
            type: "video/mp4"
          }
        ]
      },
      results: Object,
    }
  },
  methods: {
    allowEnd() {
      document.getElementById('video-continue').disabled = false;
    },
    videoEnd() {
      this.video = false;
      this.headline = true;
    },
    goToStats() {
      this.headline = false;
      this.news = false;
      this.stats = true;
    },
    async loadState() {
      const response = await loadState();
      if (!response.data.error && response.data.state) this.$store.commit('setSaveData', response.data.state)
      else console.error(response.data.message);
    },
    async logout() {
      await logOut();
      await this.$router.push("/login");
    },
  },
  computed: {
    videoSrc () {
      switch (this.results.video) {
        case 'täyspelastus':
          return TaysPelastus;
        case 'tsunami':
          return Tsunami;
        case 'puolituho':
          return PuoliTuho;
        case 'täystuho':
          return TaysTuho
        default:
          return TaysTuho;
      }
    }
  },
  mounted() {
    this.results = new Results();
    this.results.result(this.$store.state.player.savaData.times, this.$store.state.player.savaData.times.combined)
    this.videoOptions.sources[0].src = this.videoSrc;

    saveState({
      completed: true,
      casualties: this.results.casualtiesNr,
      earthSurvived: this.results.earthSurvived,
    });

    this.loadState();
  }
}
</script>

<style scoped lang="scss">
@import '~video.js/dist/video-js.css';
.btn-end {
  background: transparent linear-gradient(180deg, #20F7FE 0%, #133D53 100%);
  border-right: 3px;
  border: none;
  font-size: 25px;
  letter-spacing: 0.1em;
  color: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 350px;
  width: 100%;
}
.btn-news:disabled {
  background: #5E5E5E;
  opacity: 1;
}
</style>