<template>
  <BlueWrapper
    title="OHJUSTEN HALLINTA"
    :password="gameState.password"
    :class="gameState.gameEnd ? 'part4 game-end' : 'part4'"
  >
    <template v-slot:password>
      <div class="button-container" v-if="gameState.gameEnd">
        <h2 class="px-3 px-md-5">
          Olet onnistuneesti läpäissyt 4 vaihetta, laukaise ohjus painamalla
          punaista nappia!
        </h2>
        <div @click="setPos" :class="buttonActive ? 'btn red-btn active' : 'btn red-btn'">
          <div class="redButton">
            <RedButton />
          </div>
          <div class="buttonPressed">
            <ButtonPressed />
          </div>
        </div>
      </div>
      <div v-else>
        <IntroAnimation
          title="Tervetuloa ohjusten hallinta järjestelmään vaiheeseen"
          stage="4"
        />
        <div class="password-animation">
          <h2>SYÖTÄ VARMISTUSKOODI</h2>
          <p>VAIHE 4/4</p>
          <PasswordChecker
            double-check-code="3XIT3"
            v-on:success="handleSuccess"
            v-on:error="handleError"
            password="56412783"
            type="number"
          />
        </div>
      </div>
    </template>
    <template v-slot:animation>
      <div class="top-text">
        <h2>Ohjuksen käynnistys vaihe 4/4</h2>
        <p id="time-part-4" class="time">
          <span class="peli-aika-boksi_svg__days">00</span>
          <span>:</span>
          <span class="peli-aika-boksi_svg__hours">00</span>
          <span>:</span>
          <span class="peli-aika-boksi_svg__minutes">00</span>
          <span>:</span>
          <span class="peli-aika-boksi_svg__seconds">00</span>
        </p>
      </div>
      <Missile />
      <AnimatedBox
        blue-title="SUUTIN & MOOTTORI"
        yellow-title="SYSTEM: OHJUKSEN VAIHE 4/4 SUORITETTU"
      />
      <button @click="setEnd" class="btn btn-continue">Jatka</button>
    </template>
    <template v-if="!aborted" v-slot:abort>
      <AbortModal
        v-on:abort="abortPart"
      />
    </template>
  </BlueWrapper>
</template>

<script>
import BlueWrapper from "@/components/partials/BlueWrapper";
import PasswordChecker from "@/components/partials/PasswordChecker";
import Missile from "@/assets/ohjus.svg?inline";
import {  saveState } from "@/firebase";
import {Timer, countUpFromTime, convertToClock, stopCounter} from "@/timer";
import { Howl } from "howler";
import IntroMusicMp3 from "@/assets/audio/osa4-intro.mp3";
import IntroMusicWebm from "@/assets/audio/osa4-intro.webm";
import LoopMusicMp3 from "@/assets/audio/osa4-loop.mp3";
import LoopMusicWebm from "@/assets/audio/osa4-loop.webm";
import AnimatedBox from "@/components/partials/AnimatedBox";
import IntroAnimation from "@/components/partials/IntroAnimation";
import AbortModal from "@/components/partials/AbortModal";
import RedButton from "@/assets/red-button-up.svg?inline";
import ButtonPressed from "@/assets/red-button-down.svg?inline";

// setup music
const introMusic = new Howl({
  src: [IntroMusicWebm, IntroMusicMp3],
});
const loopMusic = new Howl({
  src: [LoopMusicWebm, LoopMusicMp3],
  loop: true,
});

export default {
  name: "Part4",
  components: {
    AbortModal,
    IntroAnimation,
    AnimatedBox,
    BlueWrapper,
    PasswordChecker,
    Missile,
    RedButton,
    ButtonPressed
  },
  data() {
    return {
      timer: {
        interval: null,
        clock: null,
        offset: null,
        clockStart: null,
      },
      buttonActive: false,
    };
  },
  mounted() {
    if (!this.gameState.gameEnd) {
      // Start Timer
      this.timer.clockStart = this.gameState.clockStart;
      this.timer = new Timer();
      this.timer.start(this.gameState.clockStart);
      this.$store.commit("setGameState", {
        part: "part4",
        type: "clockStart",
        value: this.timer.clockStart,
      });

      // Start Counter
      countUpFromTime(
        this.timer.clockStart,
        "peli-aika-boksi_svg__Group_1761-2"
      );
    } else {
      this.finalClock();
    }

    // Play the music
    introMusic.play();
    introMusic.once("end", () => {
      loopMusic.play();
    });

    if (this.gameState.password) {
      this.animationClock();
    }

    if (this.gameState.gameEnd) {
      this.finalClock();
    }
  },
  updated: function () {
    if (this.gameState.password) {
      this.animationClock();
    }
    if (this.gameState.gameEnd) {
      this.finalClock();
      window.scrollTo(0, 0);
    }
  },
  beforeDestroy() {
    this.musicStop();
    stopCounter();
  },
  computed: {
    gameState() {
      return this.$store.state.game.part4;
    },
    partTimes() {
      return this.$store.state.player.savaData.times;
    },
    aborted() {
      return this.$store.state.player.savaData.aborted4;
    },
  },
  methods: {
    handleSuccess: function () {
      stopCounter();
      const time = this.timer.clock;
      this.timer.stop();
      const combined =
        time + this.partTimes[1] + this.partTimes[2] + this.partTimes[3];

      saveState({
        times: {
          4: time,
          combined: combined,
        },
      });
      this.$store.commit("setGameState", {
        part: "part4",
        type: "finalClock",
        value: time,
      })
      this.$store.commit("setGameState", {
        part: "part4",
        type: "password",
        value: true,
      })
    },
    handleError: function () {},
    setEnd() {
      this.$store.commit("setGameState", {
        part: "part4",
        type: "gameEnd",
        value: true,
      });
      this.$store.commit("setGameState", {
        part: "part4",
        type: "password",
        value: false,
      });
    },
    setPos() {

      let self = this;
      self.buttonActive = true;
      setTimeout(function () {
        self.buttonActive = false;
        setTimeout(function () {
          self.moveOn();
        }, 500)
      }, 1000)
    },
    moveOn() {
      // Stop music
      this.musicStop();

      saveState({
        current_section: 5,
      });
      this.$store.commit("setPosition", "end");
    },
    animationClock() {
      let finalClock = this.$store.state.game.part4.finalClock;
      if (finalClock !== null) {
        console.debug('Store FinalClock');
        convertToClock(finalClock, "time-part-4");
      } else {
        finalClock = this.$store.state.player.savaData.times[4];
        convertToClock(finalClock, "time-part-4");
      }
    },
    finalClock() {
      let finalClock = this.$store.state.game.part4.finalClock;
      if (finalClock !== null) {
        console.debug('Store FinalClock');
        convertToClock(finalClock, "peli-aika-boksi_svg__Group_1761-2");
      } else {
        finalClock = this.$store.state.player.savaData.times[4];
        convertToClock(finalClock, "peli-aika-boksi_svg__Group_1761-2");
      }
    },
    musicStop() {
      // Stop music
      if (introMusic.playing()) {
        introMusic.once("fade", () => {
          introMusic.stop();
        });
        introMusic.fade(introMusic.volume(), 0, 2000);
      }
      if (loopMusic.playing()) {
        loopMusic.once("fade", () => {
          loopMusic.stop();
        });
        loopMusic.fade(loopMusic.volume(), 0, 2000);
      }
    },
    async abortPart() {
      await this.musicStop();
      await stopCounter();
      await saveState({
        aborted4: true,
      });
      await this.$store.commit('resetPartFour');
      this.$store.commit("setPosition", "start");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~bootstrap";
@import "~@/scss/custom";
.ohjus_svg__missile {
  animation: 12s ease-in-out 1s missileAnimation;
  .ohjus_svg__chamber4 {
    animation: 12s ease-in 0s fadeToYellow;
  }
}
.red-btn {
  svg {
    width: 45%;
  }
  .buttonPressed {
    display: none;
  }
  &.active {
    .buttonPressed {
      display: block;
    }
    .redButton {
      display: none;
    }
  }
  /*width: 100px;
  height: 100px;
  background: red;
  border-radius: 50%;
  @include media-breakpoint-up(md) {
    width: 150px;
    height: 150px;
  }*/
}
</style>
