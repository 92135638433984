import { round } from 'lodash';

export class Results {
    text;
    casualties;
    casualtiesNr;
    earthSurvived;
    video;

    result(times, combined) {
        const hour = 60 * 60 * 1000;
        const totalMax = hour * 4;
        const sectionTimes = [
            times['1'],
            times['2'],
            times['3'],
            times['4']
        ];
        console.debug(sectionTimes);
        let overHour = 0;
        let maxTime = 0;
        if (combined >= totalMax) maxTime = 1;

        for (let i = 0; i < sectionTimes.length; ++i) {
            if (sectionTimes[i] > hour)
                overHour++;
        }

        switch (overHour) {
            case 0:
                this.text = 'Asteroidi tuhoutui täysin ja Maapallo pelastui täysin!';
                this.casualties = 'Kuolonuhreja: 0';
                this.video = 'täyspelastus';
                this.casualtiesNr = 0;
                this.earthSurvived = true;
                break;
            case 1:
                switch (maxTime) {
                    case 0:
                        this.text = 'Maapallo pelastui! Vain pieni osa asteroidista iskeytyi mereen - evakuoinnin ansiosta lähinnä rakenteellisia vahinkoja.';
                        this.casualties = 'Kuolonuhreja: 5 hlö';
                        this.video = 'tsunami';
                        this.casualtiesNr = 5;
                        this.earthSurvived = true;
                        break;
                    case 1:
                        this.text = 'Maapallo pelastui. Pieni osa asteroidista iskeytyi kuitenkin valtamereen aiheuttaen tuhoisan tsunamin.';
                        this.casualties = 'Kuolonuhreja: n. 94 000';
                        this.video = 'tsunami';
                        this.casualtiesNr = 94000;
                        this.earthSurvived = true;
                        break;
                }
                break;
            case 2:
                switch (maxTime) {
                    case 0:
                        this.text = 'Maapallo pelastui. Osa asteroidista iskeytyi kuitenkin mereen aiheuttaen valtavan tuhoisan tsunamin.';
                        this.casualties = 'Kuolonuhreja: n. 760 000';
                        this.video = 'tsunami';
                        this.casualtiesNr = 760000;
                        this.earthSurvived = true;
                        break;
                    case 1:
                        this.text = 'Maapallo pelastui. Osa asteroidista iskeytyi kuitenkin Välimerelle tuhoisin seurauksin.';
                        this.casualties = 'Kuolonuhreja: n. 6 700 000';
                        this.video = 'puolituho';
                        this.casualtiesNr = 6700000;
                        this.earthSurvived = true;
                        break;
                }
                break;
            case 3:
                switch (maxTime) {
                    case 0:
                        this.text = 'Maapallo pelastui osittain. Iso osa asteroidista iskeytyi tuhoisasti Välimerelle.';
                        this.casualties = 'Kuolonuhreja: n. 89 000 000';
                        this.video = 'puolituho';
                        this.casualtiesNr = 89000000;
                        this.earthSurvived = true;
                        break;
                    case 1:
                        this.text = 'Maapallo pelastui täpärästi. Suuri osa asteroidista iskeytyi maahan aiheuttaen tuhoa kolmella mantereella.';
                        this.casualties = 'Kuolonuhreja: n. 472 000 000';
                        this.video = 'puolituho';
                        this.casualtiesNr = 472000000;
                        this.earthSurvived = true;
                        break;
                }
                break;
            case 4:
                this.text = 'Lähetys avaruusasemalta: Maapallo tuhoutui täysin.';
                this.casualties = 'Kuolonuhreja lähes 8 000 000 000. Kourallinen ihmisistä pelastui kansainvälisellä avaruusasemalla.';
                this.video = 'täystuho';
                this.casualtiesNr = 8000000000;
                this.earthSurvived = false;
        }
    }
}

export class Statistics {
    section1;
    section2;
    section3;
    section4;
    combined;
    saved;
    destroyed;
    casualties;

    stats(statsData, playerStats) {
        const statsAmount = statsData.length;
        // Amount of results better than current player
        let sectionOneAmount = 0;
        let sectionTwoAmount = 0;
        let sectionThreeAmount = 0;
        let sectionFourAmount = 0;
        let combinedAmount = 0;
        let savedAmount = 0;
        let destroyedAmount = 0;
        let casualtiesAmount = 0;

        statsData.forEach((element) => {
            if (element.times['1'] < playerStats.times['1']) {
                sectionOneAmount++;
            }
            if (element.times['2'] < playerStats.times['2']) {
                sectionTwoAmount++;
            }
            if (element.times['3'] < playerStats.times['3']) {
                sectionThreeAmount++;
            }
            if (element.times['4'] < playerStats.times['4']) {
                sectionFourAmount++;
            }
            if (element.times.combined < playerStats.times.combined) {
                combinedAmount++;
            }
            if (element.earthSurvived) {
                savedAmount++;
            }
            if (!element.earthSurvived) {
                destroyedAmount++;
            }
            casualtiesAmount += element.casualties;
        })
        this.section1 = round(sectionOneAmount / statsAmount * 100, 2).toLocaleString('fi-FI');
        this.section2 = round(sectionTwoAmount / statsAmount * 100, 2).toLocaleString('fi-FI');
        this.section3 = round(sectionThreeAmount / statsAmount * 100, 2).toLocaleString('fi-FI');
        this.section4 = round(sectionFourAmount / statsAmount * 100, 2).toLocaleString('fi-FI');
        this.combined = round(combinedAmount / statsAmount * 100, 2).toLocaleString('fi-FI');
        this.saved = savedAmount.toLocaleString('fi-FI');
        this.destroyed = destroyedAmount.toLocaleString('fi-FI');
        this.casualties = round(casualtiesAmount / statsAmount).toLocaleString('fi-FI');
    }
}
