<template>
  <div class="start-window">
    <div class="container">
      <div v-if="start" class="row flex-column align-items-center">
        <img class="exite-logo" :src="Exite" alt="" />
        <img class="purple-logo" :src="PurpleLogo" alt="" />
        <div class="col-md-6 text-center">
          <p class="text">{{ text }}</p>
        </div>
      </div>
      <div v-else class="text-center">
        <img class="purple-logo" :src="PurpleLogo" alt="" />
      </div>
      <slot name="start-content"></slot>
      <slot name="start-buttons"></slot>
    </div>
  </div>
</template>

<script>
import Exite from "@/assets/ExiteBOX_logo.svg";
import PurpleLogo from "@/assets/asteroidalert-logo-lila.png";

export default {
  name: "StartWrapper",
  props: {
    text: String,
    start: Boolean,
  },
  data() {
    return {
      Exite,
      PurpleLogo
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped lang="scss">
@import "~@/scss/variables";
@import "~bootstrap";

.start-window {
  background-image: url("~@/assets/asteroid-alert-bckgrnd-phone-1080x1920.png");
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  padding-top: 27px;
  @include media-breakpoint-up(lg) {
    padding-top: 15vh;
    background-image: url("~@/assets/asteroid-alert-dekstop-bckgrnd.jpg");
  }
  .text {
    font-size: 18px;
    color: #fff;
    min-height: 75px;
    margin-bottom: 25px;
    @include media-breakpoint-up(md) {
      font-size: 25px;
      margin-bottom: 50px;
    }
  }
}
.exite-logo {
  max-width: 110px;
  margin-bottom: 50px;
  @include media-breakpoint-up(md) {
    max-width: 220px;
  }
}
.purple-logo {
  max-width: 254px;
  margin-bottom: 25px;
  @include media-breakpoint-up(md) {
    margin-bottom: 50px;
    max-width: 381px;
  }
}
.statistics {
  background: rgba(0,0,0,0.8);
  border-radius: 10px;
  height: 75vh;
  padding-top: 50px;
  overflow-y: scroll;
  @include media-breakpoint-up(lg) {
    height: 50vh;
  }
}
.btn-news {
  background: transparent linear-gradient(180deg, #7A1597 0%, #411353 100%);
  color: #fff;
  padding: 16px 0;
  max-width: 350px;
  width: 100%;
  font-size: 25px;
  letter-spacing: 0.1em;
  border: none;
  margin-top: 25px;
  @include media-breakpoint-up(md) {
    margin-top: 55px;
  }
  &:hover {
    color: #fff;
  }
  &.disabled {
    background: #5E5E5E;
    opacity: 1;
  }
  &.attention {
    background: $red;
  }
}

</style>