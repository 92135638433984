<template>
  <section class="news-window">
    <div class="container">
      <div class="col-8 col-md-5">
        <GraphicsTop />
      </div>
      <slot name="news-content"></slot>
      <div class="offset-4 offset-md-8">
        <GraphicsBottom />
      </div>
      <slot name="news-buttons"></slot>
    </div>
  </section>
</template>

<script>
import GraphicsTop from "@/assets/News-top-graphics-single.svg?inline";
import GraphicsBottom from "@/assets/News-bot-graphics-single.svg?inline";

export default {
  name: "NewsWrapper",
  components: { GraphicsTop, GraphicsBottom },
  data() {
    return {
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped lang="scss">
@import "~@/scss/variables";
@import "~bootstrap";

.news-window {
  background-image: url("~@/assets/asteroid-alert-news-bckgrnd.jpg");
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  padding-top: 27px;
  @include media-breakpoint-up(lg) {
    padding-top: 7vh;
    padding-bottom: 7vh;
  }
  .video-container, .news {
    position: relative;
    &:before, &:after {
      position: absolute;
      width: 100%;
      background: #fff;
      display: block;
      content: '';
    }
    &:before {
      top: -20px;
      height: 20px;
      @include media-breakpoint-up(lg) {
        top: -30px;
        height: 30px;
      }
    }
    &:after {
      bottom: -20px;
      height: 20px;
      @include media-breakpoint-up(lg) {
        bottom: -30px;
        height: 30px;
      }
    }
  }
  .news {
    background: #000;
    height: 0;
    padding-bottom: 56.25%;
    @include media-breakpoint-up(md) {}
    .news-content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    h1 {
      font-size: 20px;
      color: #fff;
      letter-spacing: 0.1em;
      margin-bottom: 20px;
      @include media-breakpoint-up(md) {
        font-size: 50px;
        margin-bottom: 40px;
      }
    }
    p {
      font-size: 16px;
      letter-spacing: 0.1em;
      color: #fff;
      @include media-breakpoint-up(md) {
        font-size: 25px;
      }
    }
  }
  svg {
    position: relative;
    z-index: 1;
  }
}
.btn-news {
  background: transparent linear-gradient(180deg, #7A1597 0%, #411353 100%);
  color: #fff;
  padding: 16px 0;
  max-width: 350px;
  width: 100%;
  font-size: 25px;
  letter-spacing: 0.1em;
  border: none;
  margin-top: 25px;
  @include media-breakpoint-up(md) {
    margin-top: 55px;
  }
  &:hover {
    color: #fff;
  }
  &.disabled {
    background: #5E5E5E;
    opacity: 1;
  }
  &.attention {
    background: $red;
  }
}
</style>