<template>
  <div class="vikasieto-wrapper">
    <div class="container text-center">
      <h1>VIKASIETOTILA</h1>
      <div class="row">
        <div class="col-md-4">
          <div class="nr-container">
            <div v-for="index in 54" :key="index" class="nr">
              {{ populateNr(4) }}
            </div>
          </div>
          <div class="input-number">
            <input @input="passFieldHandler($event)" v-model.number="digit1" @focus="alwaysLast($event)" min="0" max="9" type="number" name="digit1" class="digit">
          </div>
        </div>
        <div class="col-md-4">
          <div class="nr-container">
            <div v-for="index in 54" :key="index" class="nr">
              {{ populateNr(9) }}
            </div>
          </div>
          <div class="input-number">
            <input @input="passFieldHandler($event)" v-model.number="digit2" @focus="alwaysLast($event)" type="number" min="0" max="9" name="digit2" class="digit">
          </div>
        </div>
        <div class="col-md-4">
          <div class="nr-container">
            <div v-for="index in 54" :key="index" class="nr">
              {{ populateNr(1) }}
            </div>
          </div>
          <div class="input-number">
            <input @input="passFieldHandler($event)" v-model.number="digit3" @focus="alwaysLast($event)" type="number" min="0" max="9" name="digit3" class="digit">
          </div>
        </div>
      </div>
      <button class="btn btn-green" v-on:click="checkInputs">JATKA</button>
    </div>
    <div v-bind:class="overrideOverlay">
      <GreenStripes/>
      OVERRIDE SUCCESS
    </div>
  </div>
</template>

<script>
import { numbers } from "@/utils";
import GreenStripes from "@/assets/system-error-green-stripes.svg?inline";

export default {
  name: "Vikasieto",
  components: { GreenStripes },
  data() {
    return {
      digit1: '',
      digit2: '',
      digit3: '',
      overrideOverlay: {
        active: false,
        'override-success': true
      }
    }
  },
  methods: {
    populateNr(exclude) {
      const min = 0;
      const max = 9;
      let rand = null;
      while(rand === null || rand === exclude) {
        rand = Math.floor(Math.random() * (max - min + 1) + min);
      }
      return rand
    },
    alwaysLast(event) {
      const target = event.target;
      target.value = ''
    },
    checkInputs() {
      if (this.digit1 === 4 && this.digit2 === 9 && this.digit3 === 1) {
        this.success()
      }
    },
    passFieldHandler(event) {
      const key = event.data;
      const target = event.target;
      if (!numbers.includes(key)) {
        console.debug(key)
        target.value = "";
        event.preventDefault();
        return;
      }
      console.debug(target.value);
      if (target.value.length >= 1) {
        let inputted = 0;
        if (!isNaN(parseInt(target.value.substr(-1, 1))))
          inputted = target.value.substr(-1,1);
        target.value = inputted;
        this[target.name] = parseInt(target.value);
      }
    },
    success() {
      let i = 0
      const interval = setInterval(() => {
        this.overrideOverlay.active === true ? this.overrideOverlay.active = false : this.overrideOverlay.active = true
        i++
        if (i > 2) {
          clearInterval(interval)
          let self = this
          setTimeout(function () {
            self.$store.commit('setHackState', 'overridden')
          }, 1000)
        }
      }, 1000);
    }
  },
  mounted() {
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

.vikasieto-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

h1 {
  color: white;
  font-size: 65px;
  text-align: center;
  @include media-breakpoint-up(md){
    font-size: 132px;
  }
}
.nr-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". . . . . . . . ."
    ". . . . . . . . ."
    ". . . . . . . . ."
    ". . . . . . . . ."
    ". . . . . . . . ."
    ". . . . . . . . .";
  border: 4px solid #00C600;
  margin-bottom: 35px;
  background: black;
  .nr {
    color: #00C600;
    font-size: 35px;
    text-align: center;
    margin: 0;
    @include media-breakpoint-up(xl) {
      margin: 10px 0;
    }
  }
}
.input-number {
  width: 108px;
  height: 101px;
  border: 4px solid #00C600;
  margin: 0 auto;
  position: relative;
  background: #00C60040;
  @include media-breakpoint-down(sm) {
    margin-bottom: 35px;
  }
  &:before {
    content: '';
    width: 4px;
    height: 35px;
    background: #00C600;
    display: block;
    position: absolute;
    top: -39px;
    left: calc(50% - 2px);
  }
  .digit {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    font-size: 67px;
    color: white;
    text-align: center;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn-green {
  padding: 16px 151px;
  background: #55C529;
  color: #fff;
  border-radius: 3px;
  font-size: 25px;
  margin-top: 60px;
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 16px 0;
  }
}
</style>