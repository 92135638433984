<template>
  <div class="intro-animation">
    <h2 class="intro">
      {{ title }}
    </h2>
    <p class="stage">{{ stage }}</p>
  </div>
</template>

<script>
export default {
  name: "IntroAnimation",
  props: {
    title: String,
    stage: String,
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/variables";
@import "~@/scss/keyframes";

.intro-animation {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: 1s ease-in-out 0.3s fadeIn forwards,
    0.7s ease-in-out 4.5s fadeIn forwards reverse;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .intro {
    transform: translateY(60px);
    animation: 1s linear 1.3s moveTitle forwards;
  }
  .stage {
    opacity: 0;
    color: $yellow;
    font-size: 44px;
    animation: 1.5s ease-in-out 2.7s showStageNumber forwards;
  }
}

@keyframes showStageNumber {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes moveTitle {
  from {
    transform: translateY(60px);
  }
  to {
    transform: translateY(0px);
  }
}
</style>
