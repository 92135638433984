<template>
  <vue-terminal
      title="Exite terminal v.0.9.5"
      :task-list="taskList"
      :command-list="commandList"
      default-task="defaultTask"
      defaultTaskCommandd="init Terminal"
      prompt="Salasana: "
      :showHelpMessage="false"
      :unknownCommandMessage="unknown">
  </vue-terminal>
</template>

<script>
import VueTerminal from 'vue-terminal'

function generateTime() {
  const timeNow = new Date();
  const hours = timeNow.getHours();
  const minutes = timeNow.getMinutes();
  const seconds = timeNow.getSeconds();
  let timeString = '' + hours;
  timeString += (minutes < 10 ? ':0' : ':') + minutes;
  timeString += (seconds < 10 ? ':0' : ':') + seconds;
  return timeString
}

const mockData = [
  { time: generateTime(), type: 'info', label: 'Info', message: 'Terminal Initializing ............' },
  { time: generateTime(), type: 'success', label: 'Success', message: 'Terminal initialized'},
  { time: generateTime(), type: 'warning', label: 'warning', message: 'Unknown connection incoming!' },
  { time: generateTime(), type: 'error', label: 'Error', message: 'Intrusion detected!' },
]

export default {
  name: "Terminal",
  components: {VueTerminal},
  data() {
    let self = this
    return {
      unknown: { time: generateTime(), type:'error', label: 'Error', message: 'Väärä salasana!' },
      taskList: {
        defaultTask: {
          help: false,
          description: 'Starting the terminal.',
          defaultTask(pushToList) {
            let i = 0;
            return new Promise(resolve => {
              const interval = setInterval(() => {
                mockData[i].time = generateTime()
                pushToList(mockData[i]);
                i++
                if (!mockData[i]) {
                  clearInterval(interval)
                  resolve({
                    time: generateTime(),
                    type: 'hacker',
                    label: 'Message from Yan',
                    message: 'Maailman kohtalo on nyt minun käsissäni. Mikäli haluatte ohjuksen kontrollin takaisin, siirtäkää 1 000 000 euron arvosta Bitcoineja tililleni 60 minuutin sisällä. Ellette toteuta vaatimustani, on kohtalo kaikilla kauhea! Tilin tiedot on lähetetty ASTR:n johtajalle. Jos et ole Taneli, lähetä hänelle sähköpostia otsikolla KOHTALO ja välitä viestini hänelle.'
                  })
                }
              }, 1000);
            })
          }
        },
        H4CK3R: {
          help: false,
          H4CK3R() {
            return new Promise(resolve => {
              setTimeout(function () {
                self.$store.commit('setHackPosition', 'vikasietotila')
              }, 2000)
              resolve({time: generateTime(), type: 'success', label: 'Success', message:'Oikea salasana syötetty!!'})
            })
          }
        }
      },
      commandList: {
        version: {
          description: 'Return this project version',
          messages: [
            {message: '0.9.5'}
          ]
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>