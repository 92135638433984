<template>
  <div class="game-window">
    <img class="hero" :src="Hero" alt="" />
    <div class="title">
      <h1>{{ title }}</h1>
    </div>
    <div v-if="login" class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 login-container">
          <Password />
          <div class="login-content">
            <slot name="login"></slot>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="password" class="container">
      <div class="missile-animation">
        <Password />
        <div class="animation-content">
          <div
            class="row flex-column text-white text-center align-items-center position-relative"
          >
            <slot name="animation"></slot>
          </div>
        </div>
      </div>
      <slot name="animation-buttons"></slot>
    </div>
    <div v-else class="container">
      <div class="row text-white">
        <div
          class="order-2 col-6 order-lg-1 col-lg-3 pr-2 pr-lg-3 left-container"
          :class="{ 'text-danger': errorRocket }"
        >
          <Rocket />
        </div>
        <div
          class="order-1 col-12 order-lg-2 col-lg-6 middle-container d-flex flex-column"
        >
          <div class="row order-2 order-md-1 py-2 py-lg-0">
            <div
              class="col-12 align-items-center text-center d-flex flex-column password-container"
            >
              <Password />
              <div class="password-inner">
                <slot name="password"></slot>
              </div>
            </div>
          </div>
          <div class="row order-1 order-md-2 pt-md-2 pb-md-3">
            <div
              class="col-8 pr-1 pr-lg-2 signal-container"
              :class="{ 'text-danger': errorSignal }"
            >
              <Signal />
              <Sidnal2 />
            </div>
            <div
              class="col-4 pl-1 pl-lg-2 radar-container"
              :class="{ 'text-danger': errorRadar }"
            >
              <Radar />
            </div>
          </div>
        </div>
        <div
          class="order-3 col-6 order-lg-3 col-lg-3 pl-2 pl-lg-3 right-container"
          :class="{ 'text-danger': errorTime }"
        >
          <Time />
          <slot name="counter"></slot>
        </div>
      </div>
      <slot name="abort"></slot>
    </div>
    <div class="logos">
      <img class="exite" :src="Exite" alt="" />
      <img class="logo" :src="Logo" alt="" />
    </div>
    <img class="footer" :src="Footer" alt="" />
  </div>
</template>

<script>
import Logo from "@/assets/asteroidalert-logo-sininen.png";
import Hero from "@/assets/hero-palkki.svg";
import Footer from "@/assets/footer-palkki.svg";
import Exite from "@/assets/ExiteBOX_logo.svg";
import Rocket from "@/assets/peli-ohjus-boksi.svg?inline";
import Password from "@/assets/peli-varmistuskoodi-boxi.svg?inline";
import Signal from "@/assets/peli-singaalin-vahvuus-boxi.svg?inline";
import Sidnal2 from "@/assets/peli-signaali-2-boksi.svg?inline";
import Radar from "@/assets/peli-radar-boksi.svg?inline";
import Time from "@/assets/peli-aika-boksi.svg?inline";

export default {
  props: {
    title: String,
    login: {
      type: Boolean,
      default: false,
    },
    password: {
      type: Boolean,
      default: false,
    },
    errorRocket: {
      type: Boolean,
      default: false,
    },
    errorSignal: {
      type: Boolean,
      default: false,
    },
    errorRadar: {
      type: Boolean,
      default: false,
    },
    errorTime: {
      type: Boolean,
      default: false,
    },
  },
  name: "BlueWrapper",
  components: { Rocket, Password, Signal, Sidnal2, Radar, Time },
  data() {
    return {
      Hero,
      Footer,
      Logo,
      Exite,
    };
  },
  computed: {
    getCurrentSection() {
      return this.$store.getters.getCurrentSection;
    },
  },
  mounted() {
    window.scrollTo(0,0);
    this.$nextTick(() => {
      let loginBtn = document.querySelector(
        ".login-btn #Vahvista-nappi_svg__text"
      );
      if (loginBtn) {
        loginBtn.innerHTML = "kirjaudu";
      }

      let missileText = document.getElementById("peli-ohjus-boksi_svg__vaihe");
      if (missileText) {
        missileText.innerHTML = "VAIHE " + this.getCurrentSection + "/4 KESKEN";
      }
    });
  },
  updated: function () {
    this.$nextTick(() => {
      let missileText = document.getElementById("peli-ohjus-boksi_svg__vaihe");
      if (missileText) {
        missileText.innerHTML = "VAIHE " + this.getCurrentSection + "/4 KESKEN";
      }
    });
  },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
.game-window {
  position: relative;
  min-height: 100vh;
  height: 100%;
  background-image: url("~@/assets/asteroid-alert-bckgrnd-phone-02-1080x1920.jpg");
  background-size: cover;
  background-position: center;
  padding-bottom: 8rem;
  @include media-breakpoint-up(md) {
    background-image: url("~@/assets/asteroid-alert-dekstop-bckgrnd-02.jpg");
    padding-bottom: 15rem;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 2rem;
    margin-bottom: 1.5rem;
    h1 {
      font-family: Wallpoet, sans-serif;
      font-size: 80px;
      @include media-breakpoint-down(md) {
        font-size: 30px;
      }
      color: white;
      text-align: center;
    }
  }
}
.logos {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 50px;
  @include media-breakpoint-down(md) {
    bottom: 40px;
  }
  @include media-breakpoint-up(lg) {
    bottom: 10vh;
  }
  left: 50%;
  transform: translateX(-50%);
  object-fit: contain;
  .exite {
    width: 49px;
    margin-bottom: 10px;
    @include media-breakpoint-up(md) {
      margin-bottom: 18px;
      width: 85px;
    }
  }
  .logo {
    width: 90px;
    @include media-breakpoint-up(md) {
      width: 157px;
    }
  }
}

.missile-animation {
  position: relative;
  .time {
    font-size: 25px;
    color: $yellow;
  }
  .animation-content {
    position: absolute;
    top: 13%;
    padding: 0 6%;
    width: 100%;
  }
  .peli-varmistuskoodi-boxi_svg__password-box {
    min-height: 70vh;
    width: 100%;
    @include media-breakpoint-up(lg) {
      min-height: auto;
    }
  }
  #peli-varmistuskoodi-boxi_svg__Path_493 {
    opacity: 1;
  }
  .row {
    min-height: 55vh;
    justify-content: space-between;
    @include media-breakpoint-up(xl) {
      min-height: 591px;
    }
  }
}

.hero {
  width: 100%;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.password-container {
  position: relative;
}
.password-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  h2 {
    font-size: 19px;
    letter-spacing: 0.1em;
    margin-bottom: 6px;
    @include media-breakpoint-up(md) {
      font-size: 34px;
      margin-bottom: 1rem;
    }
  }
  p {
    color: $yellow;
    font-size: 17px;
    letter-spacing: 0.1em;
    margin-bottom: 5px;
    @include media-breakpoint-up(md) {
      font-size: 25px;
      margin-bottom: 1rem;
    }
  }
  .doublecheck {
    color: $yellow;
    font-size: 18px;
    letter-spacing: 0.1em;
  }
}
.signal-container {
  svg {
    height: 50%;
    max-width: 100%;
  }
}
.login-container {
  position: relative;
  .peli-varmistuskoodi-boxi_svg__password-box {
    height: 350px;
    width: 100%;
    @include media-breakpoint-up(md) {
      height: auto;
    }
  }
  .login-content {
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 1rem;
    .huom-kolmio-keltainen_svg__huom {
      width: 30px;
      @include media-breakpoint-up(md) {
        width: 75px;
      }
    }
    h2 {
      font-size: 18px;
      margin-bottom: 20px;
      margin-top: 6px;
      color: $yellow;
      letter-spacing: 0.1em;
      @include media-breakpoint-up(md) {
        font-size: 25px;
        margin-bottom: 2rem;
        margin-top: 1rem;
      }
    }
    input {
      background: transparent;
      border: 2px solid $cyan;
      border-radius: 0;
      text-align: center;
      margin-bottom: 10px;
      font-size: 18px;
      color: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      @include media-breakpoint-up(md) {
        font-size: 25px;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 2.5rem;
      }
    }
    .login-btn {
      padding: 0;
      max-width: 151px;
      width: 100%;
      @include media-breakpoint-up(md) {
        max-width: 296px;
      }
      .error {
        .Vahvista-nappi_svg__Path_490 {
          fill: $red;
        }
        .Vahvista-nappi_svg__Path_491 {
          fill: $red;
        }
        .Vahvista-nappi_svg__Path_498 {
          fill: $red;
        }
      }
    }
    a {
      margin-top: 1rem;
      font-size: 18px;
      letter-spacing: 0.1em;
      color: $yellow;
      @include media-breakpoint-up(md) {
        font-size: 25px;
      }
    }
  }
}
// Animations
#peli-aika-boksi_svg__Group_1761-2 {
  &.over {
    fill: $red;
    transition: 1s all;
  }
}
.part1 {
  .peli-ohjus-boksi_svg__chamber1 {
    animation-name: rocket-chamber;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
}
.part2 {
  .peli-ohjus-boksi_svg__chamber1 {
    fill: $yellow;
  }
  .peli-ohjus-boksi_svg__chamber2 {
    animation-name: rocket-chamber;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
}
.part3 {
  .peli-ohjus-boksi_svg__chamber1,
  .peli-ohjus-boksi_svg__chamber2 {
    fill: $yellow;
  }
  .peli-ohjus-boksi_svg__chamber3 {
    animation-name: rocket-chamber;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
}
.part4 {
  .peli-ohjus-boksi_svg__chamber1,
  .peli-ohjus-boksi_svg__chamber2,
  .peli-ohjus-boksi_svg__chamber3 {
    fill: $yellow;
  }
  .peli-ohjus-boksi_svg__chamber4 {
    animation-name: rocket-chamber;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
  &.game-end {
    .peli-ohjus-boksi_svg__chamber4 {
      fill: $yellow;
      animation: none;
    }
  }
}
.end {
  .missile-animation {
    .peli-varmistuskoodi-boxi_svg__password-box {
      min-height: auto;
    }
  }
}

// Rocket animations
@keyframes rocket-chamber {
  0% {
    fill: transparent;
  }
  50% {
    fill: $yellow;
  }
  100% {
    fill: transparent;
  }
}
</style>
