<template>
  <StartWrapper :start="true" text="Aloita pelaaminen valitsemalla osa.">
    <template v-slot:start-content>
      <div class="row justify-content-lg-center">
        <div v-for="(section, index) in sections"
             :class="{'col-5 offset-1 col-lg-2 offset-lg-0 pb-4 text-center': !(index % 2), 'col-5 col-lg-2 pb-4 text-center': index % 2 }"
             :key="index">
          <div v-if="currentSection === section.name" @click="goTo(section.name)" class="game-part current">
            <p>{{ section.text }}</p>
            <div class="continue">
              <Caret/>
              ALOITA
            </div>
          </div>
          <div v-else-if="currentSection > section.name" class="game-part done">
            <p>{{ section.text }}</p>
            <Check/>
          </div>
          <div v-else class="game-part">
            <p>{{ section.text }}</p>
            <Lock/>
          </div>
        </div>
      </div>
    </template>
  </StartWrapper>
</template>

<script>
import StartWrapper from "@/components/partials/StartWrapper";
import Lock from "@/assets/Lock.svg?inline";
import Caret from "@/assets/caret-square-right.svg?inline";
import Check from "@/assets/check.svg?inline";
import {loadState} from "@/firebase";

export default {
  name: "Start",
  components: {StartWrapper, Lock, Caret, Check},
  data() {
    return {
      sections: [
        {name: 1, text: 'OSA 1'},
        {name: 2, text: 'OSA 2'},
        {name: 3, text: 'OSA 3'},
        {name: 4, text: 'OSA 4'}
      ],
    }
  },
  computed: {
    currentSection() {
      return this.$store.getters.getCurrentSection;
    }
  },
  methods: {
    goTo(section) {
      this.$store.commit('setPosition', section);
    },
    async loadState() {
      const response = await loadState();
      if (!response.data.error && response.data.state) this.$store.commit('setSaveData', response.data.state)
      else console.error(response.data.message);
    }
  },
  mounted() {
    this.loadState()
  }
}
</script>

<style scoped lang="scss">
@import "~@/scss/variables";
@import "~bootstrap";

.game-part {
  height: 92px;
  background: #451991;
  opacity: 0.7;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include media-breakpoint-up(md) {
    height: 152px;
  }

  p {
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
    line-height: 1;
    margin-bottom: 13px;
    @include media-breakpoint-up(md) {
      font-size: 50px;
    }
  }

  svg {
    height: 25px;
    opacity: 0.5;
    @include media-breakpoint-up(md) {
      height: 50px;
    }

    .latch {
      transform: translateY(20px);
    }
  }

  &.done {
    background: rgba(255, 255, 255, 0.2);

    p {
      color: white;
    }
  }

  &.done {
    opacity: 1;

    p {
      opacity: 1;
    }

    svg {
      color: #fff;
      opacity: 1;
    }
  }

  &.current {
    opacity: 1;
    background: transparent linear-gradient(180deg, #7A1597 0%, #411353 100%);
    cursor: pointer;

    p {
      opacity: 1;
    }

    .continue {
      background: transparent linear-gradient(0deg, #751491 0%, #3B0A49 100%);
      margin: 0 0.5rem;
      font-size: 16px;
      color: #fff;
      letter-spacing: 2.2px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-up(md) {
        font-size: 22px;
        height: 44px;
        margin: 0 1rem;
      }

      svg {
        width: 21px;
        opacity: 1;
        margin-right: 9px;
        @include media-breakpoint-up(md) {
          width: 25px;
        }
      }
    }
  }
}
</style>