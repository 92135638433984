export class Timer {
  interval;
  clock = 0;
  offset;
  clockStart;

  start(clockStart) {
    if (!this.interval) {
      if (clockStart) {
        this.offset = clockStart;
        this.clockStart = clockStart;
      } else {
        this.clockStart = Date.now();
        this.offset = Date.now();
      }
      this.interval = setInterval(this.update.bind(this), 1);
    }
  }

  stop() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  update() {
    this.clock += this.delta();
  }

  delta() {
    const now = Date.now(),
      d = now - this.offset;

    this.offset = now;
    return d;
  }

  reset() {
    this.clock = 0;
  }

  getTime() {
    return this.clock;
  }
}

export function stopCounter() {
  clearTimeout(countUpFromTime.interval);
}

export function countUpFromTime(countFrom, id) {
  countFrom = new Date(countFrom).getTime();
  let now = new Date(),
    startFrom = new Date(countFrom),
    timeDifference = now - countFrom;

  convertToClock(timeDifference, id);

  clearTimeout(countUpFromTime.interval);
  countUpFromTime.interval = setTimeout(function () {
    countUpFromTime(startFrom, id);
  }, 1000);
}

/**
 * This function takes a time and an elements id and converts it to a readable time.
 * @param time time in ms.
 * @param id The element id.
 */
export function convertToClock(time, id) {
  const { days, daysStr, hours, hoursStr, mins, secs } = getTimeObject(time);

  const idEl = document.getElementById(id);
  if (idEl !== null) {
    if (hours > 0 || days > 0) {
      idEl.classList.add("over");
    }
    idEl.getElementsByClassName(
      "peli-aika-boksi_svg__days"
    )[0].innerHTML = daysStr;
    idEl.getElementsByClassName(
      "peli-aika-boksi_svg__hours"
    )[0].innerHTML = hoursStr;
    idEl.getElementsByClassName(
      "peli-aika-boksi_svg__minutes"
    )[0].innerHTML = mins;
    idEl.getElementsByClassName(
      "peli-aika-boksi_svg__seconds"
    )[0].innerHTML = secs;
  }
}

/**
 * Takes a time and returns a time object.
 * @param time Time in ms.
 * @returns {days, daysStr, hours, hoursStr, mins, secs} A "TimeObject".
 */
export function getTimeObject(time) {
  const secondsInADay = 60 * 60 * 1000 * 24,
    secondsInAHour = 60 * 60 * 1000;
  let timeObject = {};

  timeObject.days = Math.floor(time / secondsInADay);
  timeObject.daysStr = timeObject.days.toString().padStart(2, "0");
  timeObject.hours = Math.floor((time % secondsInADay) / secondsInAHour);
  timeObject.hoursStr = timeObject.hours.toString().padStart(2, "0");
  timeObject.mins = Math.floor(
    ((time % secondsInADay) % secondsInAHour) / (60 * 1000)
  )
    .toString()
    .padStart(2, "0");
  timeObject.secs = Math.floor(
    (((time % secondsInADay) % secondsInAHour) % (60 * 1000)) / 1000
  )
    .toString()
    .padStart(2, "0");
  return timeObject;
}
