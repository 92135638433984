import Vue from "vue";
import App from "./App.vue";
import store from "@/store";
import router from "@/routes";
import { auth } from "@/firebase";

import "popper.js";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "@/scss/custom.scss"

Vue.config.productionTip = false;

// Only render app when firebase auth is initialized, reusing the app.
let app;
auth.onAuthStateChanged(() => {
  if (!app)
    app = new Vue({
      router,
      store,
      beforeCreate () {
        this.$store.commit('initialiseStore', { store: localStorage.getItem('store'), hmac: localStorage.getItem('hmac') })
      },
      render: (h) => h(App),
    }).$mount("#app");
});
