import Vue from 'vue'
import Vuex from 'vuex'
import { sha256 } from 'js-sha256'
import { throttle } from 'lodash'

Vue.use(Vuex)

// Changing the dbVersion wipes store from all clients. Only do this if you need to change the data structure.
const dbVersion = 1
const hmacKey = '7s5qTl2pnHFlT6GoWjLVn8gu7dTJ5RnV'

const state = {
    version: dbVersion,
    game: {
        position: 'start',
        currentSection: 1,
        part1: {
            news: true,
            password: false,
            login: false,
            clockStart: null,
            finalClock: null
        },
        part2: {
            password: false,
            clockStart: null,
            finalClock: null
        },
        part3: {
            password: false,
            clockStart: null,
            finalClock: null
        },
        part4: {
            password: false,
            gameEnd: false,
            clockStart: null,
            finalClock: null
        },
        hack: {
            state: 'starting',
            position: 'terminal'
        }
    },
    player: {
        savaData: Object,
        code: String
    }
}

const initialStateCopy = JSON.parse(JSON.stringify(state))

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state,
    getters: {
        getCurrentSection: state => {
            return state.game.currentSection
        }
    },
    mutations: {
        initialiseStore (state, payload) {
            const stateJson = payload.store
            // Check if the ID exists
            if (stateJson) {
                // Validate store
                if (sha256.hmac(hmacKey, stateJson) === payload.hmac) {
                    const store = JSON.parse(stateJson)
                    // Replace the state object with the stored item
                    if (store.version === dbVersion) {
                        console.debug('Restore from localstorage')
                        this.replaceState(
                            Object.assign(state, store)
                        )
                    } else {
                        console.warn('Version mismatch')
                    }
                } else {
                    console.warn('Invalid hmac')
                }
            }
        },
        setName (state, payload) {
            state.player.userName = payload
        },
        setCode (state, payload) {
            state.player.code = payload
        },
        setPosition (state, payload) {
            state.game.position = payload
        },
        setHackState (state, payload) {
            state.game.hack.state = payload
        },
        setHackPosition (state, payload) {
            state.game.hack.position = payload
        },
        setCurrentSection (state, payload) {
            state.game.currentSection = payload
        },
        setSaveData (state, payload) {
            state.player.savaData = payload
            if (payload.current_section === 5) {
                state.game.position = 'end'
            } else if (payload.current_section) {
                state.game.currentSection = payload.current_section
            } else {
                state.game.currentSection = 1
            }
        },
        setGameState (state, payload) {
            state.game[payload.part][payload.type] = payload.value
        },
        resetPartOne (state) {
            state.game.part1.news =  true;
            state.game.part1.password = false;
            state.game.part1.login = false;
            state.game.part1.clockStart = null;
            state.game.part1.finalClock = null;
        },
        resetPartTwo (state) {
            state.game.part2.password = false;
            state.game.part2.clockStart = null;
            state.game.part2.finalClock = null;
        },
        resetPartThree (state) {
            state.game.part3.password = false;
            state.game.part3.clockStart = null;
            state.game.part3.finalClock = null;
            state.game.hack.state = 'starting';
            state.game.hack.position = 'terminal';
        },
        resetPartFour (state) {
            state.game.part4.password = false;
            state.game.part4.gameEnd = false;
            state.game.part4.clockStart = null;
            state.game.part4.finalClock = null;
        }
    }
})

// Subscribe to store updates, update at most once every second.
store.subscribe(throttle((mutation, state) => {
    if (mutation.type !== 'initialiseStore') {
        const stateJson = JSON.stringify({ ...state, ui: undefined })
        const hmac = sha256.hmac(hmacKey, stateJson)
        // Store the state object as a JSON string
        localStorage.setItem('store', stateJson)
        localStorage.setItem('hmac', hmac)
    }
}, 1000))

export function resetState() {
    store.replaceState(initialStateCopy)
    console.debug('Reset Function')
}

export default store