<template>
  <div class="animated-box-wrapper">
    <div class="animated-box">
      <h3 class="blue-title">{{ blueTitle }}</h3>
      <div class="black-box">
        <div class="scroll-text">
          01010111 01100101 01100010 01000001 01110000 01110000 00100000 01101101 01100001 01100100 01100101 00100000
          01100010 01111001 <br/>
          01001010 01000011 01001111 00100000 01000100 01101001 01100111 01101001 01110100 01100001 01101100
        </div>
        <div class="scroll-text">
          01010000 01110010 01101111 01101010 01100101 01100011 01110100 00100000 01001101 01100001 01101110 01100001
          01100111 01100101 01110010
          <br/>
          01000101 01101101 01101001 01101100 01101001 01100001 00100000 01000101 01110100 01111010 01100101 01101100
          01101100
        </div>
        <div class="scroll-text">
          01000111 01110010 01100001 01110000 01101000 01101001 01100011 00100000 01000100 01100101 01110011 01101001
          01100111 01101110
          <br/>
          01001101 01101001 01100011 01101000 01100001 01100101 01101100 00100000 01010010 11000011 10110110 01101110
          01101110 01100010 01101100 01100001 01100100
        </div>
        <div class="scroll-text">
          01000011 01101111 01100100 01101001 01101110 01100111
          <br/>
          01001010 01101111 01101000 01100001 01101110 00100000 11000011 10010110 01110011 01110100 01100101 01110010
          01100010 01100101 01110010 01100111
          <br/>
          01001101 01100001 01111000 01100101 01101101 01101001 01101100 01101001 01100001 01101110 00100000 01000111
          01110010 11000011 10110110 01101110 01100010 01101100 01101111 01101101
        </div>
      </div>
      <h3 class="yellow-text">{{ yellowTitle }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnimatedBox",
  props: {
    blueTitle: String,
    yellowTitle: String,
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped></style>
