<template>
  <section class="part1">
    <NewsWrapper v-if="gameState.news">
      <template v-slot:news-content>
        <Videoplayer class="video-container" :options="videoOptions" />
      </template>
      <template v-slot:news-buttons>
        <div class="row">
          <div class="col-md-6 text-center text-md-right">
            <button
              @click="openExite"
              :class="
                news.attention ? 'btn btn-news attention' : 'btn btn-news'
              "
            >
              Katso Exite NEWS
            </button>
          </div>
          <div @click="goToLogin" class="col-md-6 text-center text-md-left">
            <button
              :class="news.opened ? 'btn btn-news' : 'disabled btn btn-news'"
            >
              Jatka
            </button>
          </div>
        </div>
      </template>
    </NewsWrapper>
    <BlueWrapper
      v-else
      title="OHJUSTEN HALLINTA"
      :login="gameState.login"
      :password="gameState.password"
    >
      <template v-slot:login>
        <WarningYellow />
        <h2>Varoitus! vaaditaan auktorisoitu lupa!</h2>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-5">
              <input
                v-model.trim="loginForm.handle"
                type="text"
                class="form-control"
                id="handle"
                placeholder="Tunnus"
                required
              />
            </div>
            <div class="col-12 col-md-5">
              <input
                v-model.trim="loginForm.password"
                class="form-control"
                type="password"
                id="password"
                placeholder="Salasana"
                required
              />
            </div>
          </div>
        </div>
        <button class="btn login-btn">
          <LoginBtn @click="validateLogin" :class="{ error: loginForm.error }">
          </LoginBtn>
        </button>
        <a href="https://livenews.exite.site/" target="_blank"
          >Ota yhteys chatin kautta toimittajaan</a
        >
      </template>
      <template v-slot:password>
        <IntroAnimation
          title="Tervetuloa ohjusten hallinta järjestelmään vaiheeseen"
          stage="1"
        />
        <div class="password-animation">
          <h2>SYÖTÄ VARMISTUSKOODI</h2>
          <p>VAIHE 1/4</p>
          <PasswordChecker
            :should-show="true"
            double-check-code="A5TRX"
            v-on:success="handleSuccess"
            v-on:error="handleError"
            password="11111299"
            type="number"
          />
        </div>
      </template>
      <template v-slot:animation>
        <div class="top-text">
          <h2>Ohjuksen käynnistys vaihe 1/4</h2>
          <p id="time-part-1" class="time">
            <span class="peli-aika-boksi_svg__days">00</span>
            <span>:</span>
            <span class="peli-aika-boksi_svg__hours">00</span>
            <span>:</span>
            <span class="peli-aika-boksi_svg__minutes">00</span>
            <span>:</span>
            <span class="peli-aika-boksi_svg__seconds">00</span>
          </p>
        </div>
        <Missile />
        <AnimatedBox
          blue-title="SUUTIN & MOOTTORI"
          yellow-title="SYSTEM: OHJUKSEN VAIHE 1/4 SUORITETTU"
        />
        <button @click="setPos" class="btn btn-continue">Jatka</button>
      </template>
      <template v-slot:counter>
        <div id="time-counter">
          <span class="days"></span>
          <span class="hours"></span>
          <span class="minutes"></span>
          <span class="seconds"></span>
        </div>
      </template>
      <template v-if="!aborted" v-slot:abort>
        <AbortModal v-on:abort="abortPart" />
      </template>
    </BlueWrapper>
  </section>
</template>

<script>
import BlueWrapper from "@/components/partials/BlueWrapper";
import PasswordChecker from "@/components/partials/PasswordChecker";
import Missile from "@/assets/ohjus.svg?inline";
import LoginBtn from "@/assets/Vahvista-nappi.svg?inline";
import WarningYellow from "@/assets/huom-kolmio-keltainen.svg?inline";
import { saveState } from "@/firebase";
import { Timer, countUpFromTime, stopCounter, convertToClock } from "@/timer";
import { Howl } from "howler";
import IntroMusicMp3 from "@/assets/audio/osa1-intro.mp3";
import IntroMusicWebm from "@/assets/audio/osa1-intro.webm";
import LoopMusicMp3 from "@/assets/audio/osa1-loop.mp3";
import LoopMusicWebm from "@/assets/audio/osa1-loop.webm";
import NewsWrapper from "@/components/partials/NewsWrapper";
import Videoplayer from "@/components/partials/Videoplayer";
import IntroVideo from "@/assets/video/Intro_Video.mp4";
import AnimatedBox from "@/components/partials/AnimatedBox";
import IntroAnimation from "@/components/partials/IntroAnimation";
import AbortModal from "@/components/partials/AbortModal";

// setup music
const introMusic = new Howl({
  src: [IntroMusicWebm, IntroMusicMp3],
});
const loopMusic = new Howl({
  src: [LoopMusicWebm, LoopMusicMp3],
  loop: true,
});

export default {
  name: "Part1",
  components: {
    AbortModal,
    IntroAnimation,
    Videoplayer,
    NewsWrapper,
    BlueWrapper,
    PasswordChecker,
    Missile,
    LoginBtn,
    WarningYellow,
    AnimatedBox,
  },
  data() {
    return {
      news: {
        opened: false,
        attention: false,
      },
      loginForm: {
        handle: "",
        password: "",
        error: false,
      },
      timer: {
        interval: null,
        clock: null,
        offset: null,
        clockStart: null,
      },
      videoOptions: {
        autoplay: true,
        controls: true,
        fluid: true,
        sources: [
          {
            src: IntroVideo,
            type: "video/mp4",
          },
        ],
      },
    };
  },
  mounted() {
    // Start Timer
    this.timer.clockStart = this.gameState.clockStart;
    this.timer = new Timer();
    this.timer.start(this.gameState.clockStart);
    this.$store.commit("setGameState", {
      part: "part1",
      type: "clockStart",
      value: this.timer.clockStart,
    });

    // Start Counter
    if (
      !this.gameState.news &&
      !this.gameState.login &&
      !this.gameState.password
    ) {
      countUpFromTime(
        this.timer.clockStart,
        "peli-aika-boksi_svg__Group_1761-2"
      );
    }

    if (!this.gameState.news && !this.gameState.login) {
      // Play the music
      introMusic.play();
      introMusic.once("end", () => {
        loopMusic.play();
      });
    }
    if (this.gameState.password) {
      this.finalClock();
    }
  },
  updated: function () {
    if (this.gameState.password) {
      this.finalClock();
    }
  },
  beforeDestroy() {
    stopCounter();
  },
  computed: {
    gameState() {
      return this.$store.state.game.part1;
    },
    aborted() {
      return this.$store.state.player.savaData.aborted1;
    },
  },
  methods: {
    openExite: function () {
      // Open new window in middle of screen
      // Probably wont work on phones. Too bad!
      const y = window.outerHeight / 2 + window.top.screenY - 500 / 2;
      const x = window.outerWidth / 2 + window.top.screenX - 1000 / 2;
      window.open(
        "https://livenews.exite.site",
        "Exite",
        "left=" + x + ", top=" + y + ", height=500px, width=1000px"
      );
      this.news.opened = true;
    },
    goToLogin: function () {
      if (this.news.opened) {
        this.$store.commit("setGameState", {
          part: "part1",
          type: "news",
          value: false,
        });
        this.$store.commit("setGameState", {
          part: "part1",
          type: "login",
          value: true,
        });
      } else {
        console.debug("click");
        let i = 0;
        const interval = setInterval(() => {
          this.news.attention === true
            ? (this.news.attention = false)
            : (this.news.attention = true);
          i++;
          if (i > 3) {
            clearInterval(interval);
          }
        }, 1000);
      }
    },
    handleSuccess: function () {
      stopCounter();
      const completionTime = this.timer.clock;
      this.timer.stop();

      saveState({
        current_section: 2,
        times: { 1: completionTime },
      });
      this.$store.commit("setCurrentSection", 2);
      this.$store.commit("setGameState", {
        part: "part1",
        type: "finalClock",
        value: completionTime,
      });
      this.$store.commit("setGameState", {
        part: "part1",
        type: "password",
        value: true,
      });
    },
    handleError: function () {},
    setPos() {
      // Stop music
      this.musicStop();
      this.$store.commit("setPosition", "start");
    },
    finalClock() {
      let finalClock = this.$store.state.game.part1.finalClock;
      if (finalClock !== null) {
        console.debug("Store FinalClock");
        convertToClock(finalClock, "time-part-1");
      } else {
        finalClock = this.$store.state.player.savaData.times[1];
        convertToClock(finalClock, "time-part-1");
      }
    },
    musicStop() {
      // Stop music
      if (introMusic.playing()) {
        introMusic.once("fade", () => {
          introMusic.stop();
        });
        introMusic.fade(introMusic.volume(), 0, 2000);
      }
      if (loopMusic.playing()) {
        loopMusic.once("fade", () => {
          loopMusic.stop();
        });
        loopMusic.fade(loopMusic.volume(), 0, 2000);
      }
    },
    validateLogin() {
      const cap = this.loginForm.handle.toUpperCase();
      if (cap === "EXT2020" && this.loginForm.password === "20198") {
        this.$store.commit("setGameState", {
          part: "part1",
          type: "login",
          value: false,
        });

        // Start Counter
        this.$nextTick(() => {
          countUpFromTime(
            this.timer.clockStart,
            "peli-aika-boksi_svg__Group_1761-2"
          );
        });

        // Play the music
        introMusic.play();
        introMusic.once("end", () => {
          loopMusic.play();
        });
      } else {
        let self = this;
        this.loginForm.error = true;
        setTimeout(function () {
          self.loginForm.error = false;
        }, 1000);
      }
    },
    async abortPart() {
      await this.musicStop();
      await stopCounter();
      await saveState({
        aborted1: true,
      });
      await this.$store.commit("resetPartOne");
      this.$store.commit("setPosition", "start");
    },
  },
};
</script>

<style scoped lang="scss">
.ohjus_svg__missile {
  animation: 12s ease-in-out 1s missileAnimation;
  .ohjus_svg__chamber2,
  .ohjus_svg__chamber3,
  .ohjus_svg__chamber4 {
    fill: #000;
  }
  .ohjus_svg__chamber1 {
    animation: 12s ease-in 0s fadeToYellow;
  }
}
</style>
