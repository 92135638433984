<template>
  <div>
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js';

export default {
  name: "Videoplayer",
  props: {
    options: {
      type: Object,
      default() {
        return{};
      }
    }
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {
    const self = this;
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
      console.debug('onPlayerReady', this);
      this.on('ended', function () {
        console.debug('ended');
        self.$emit("videoEnded");
      });
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style scoped>

</style>