<template>
  <BlueWrapper
    title="OHJUSTEN HALLINTA"
    :password="gameState.password"
    class="part2"
  >
    <template v-slot:password>
      <IntroAnimation
        title="Tervetuloa ohjusten hallinta järjestelmään vaiheeseen"
        stage="2"
      />
      <div class="password-animation">
        <h2>SYÖTÄ VARMISTUSKOODI</h2>
        <p>VAIHE 2/4</p>
        <PasswordChecker
          double-check-code="SL33T"
          v-on:success="handleSuccess"
          v-on:error="handleError"
          password="13141636"
          type="number"
        />
      </div>
    </template>
    <template v-slot:animation>
      <div class="top-text">
        <h2>Ohjuksen käynnistys vaihe 2/4</h2>
        <p id="time-part-2" class="time">
          <span class="peli-aika-boksi_svg__days">00</span>
          <span>:</span>
          <span class="peli-aika-boksi_svg__hours">00</span>
          <span>:</span>
          <span class="peli-aika-boksi_svg__minutes">00</span>
          <span>:</span>
          <span class="peli-aika-boksi_svg__seconds">00</span>
        </p>
      </div>
      <Missile />
      <AnimatedBox
        blue-title="SUUTIN & MOOTTORI"
        yellow-title="SYSTEM: OHJUKSEN VAIHE 2/4 SUORITETTU"
      />
      <button @click="setPos" class="btn btn-continue">Jatka</button>
    </template>
    <template v-slot:counter>
      <div id="time-counter">
        <span class="days"></span>
        <span class="hours"></span>
        <span class="minutes"></span>
        <span class="seconds"></span>
      </div>
    </template>
    <template v-if="!aborted" v-slot:abort>
      <AbortModal
        v-on:abort="abortPart"
      />
    </template>
  </BlueWrapper>
</template>

<script>
import BlueWrapper from "@/components/partials/BlueWrapper";
import PasswordChecker from "@/components/partials/PasswordChecker";
import Missile from "@/assets/ohjus.svg?inline";
import {saveState} from "@/firebase";
import {Timer, countUpFromTime, stopCounter, convertToClock} from "@/timer";
import { Howl } from "howler";
import IntroMusicMp3 from "@/assets/audio/osa2-intro.mp3";
import IntroMusicWebm from "@/assets/audio/osa2-intro.webm";
import LoopMusicMp3 from "@/assets/audio/osa2-loop.mp3";
import LoopMusicWebm from "@/assets/audio/osa2-loop.webm";
import AnimatedBox from "@/components/partials/AnimatedBox";
import IntroAnimation from "@/components/partials/IntroAnimation";
import AbortModal from "@/components/partials/AbortModal";

// setup music
const introMusic = new Howl({
  src: [IntroMusicWebm, IntroMusicMp3],
});
const loopMusic = new Howl({
  src: [LoopMusicWebm, LoopMusicMp3],
  loop: true,
});

export default {
  name: "Part2",
  components: {
    AbortModal,
    AnimatedBox,
    BlueWrapper,
    PasswordChecker,
    Missile,
    IntroAnimation,
  },
  data() {
    return {
      timer: {
        interval: null,
        clock: null,
        offset: null,
        clockStart: null,
      },
    };
  },
  mounted() {
    // Start Timer
    this.timer.clockStart = this.gameState.clockStart;
    this.timer = new Timer();
    this.timer.start(this.gameState.clockStart);
    this.$store.commit("setGameState", {
      part: "part2",
      type: "clockStart",
      value: this.timer.clockStart,
    });

    // Start Counter
    countUpFromTime(this.timer.clockStart, "peli-aika-boksi_svg__Group_1761-2");

    // Play the music
    introMusic.play();
    introMusic.once("end", () => {
      loopMusic.play();
    });

    if (this.gameState.password) {
      this.finalClock();
    }
  },
  updated: function () {
    if (this.gameState.password) {
      this.finalClock();
    }
  },
  beforeDestroy() {
    stopCounter();
  },
  computed: {
    gameState() {
      return this.$store.state.game.part2;
    },
    aborted() {
      return this.$store.state.player.savaData.aborted2;
    },
  },
  methods: {
    handleSuccess: function () {
      stopCounter();
      const time = this.timer.clock;
      this.timer.stop();

      saveState({
        current_section: 3,
        times: { 2: time },
      });
      this.$store.commit("setCurrentSection", 3);
      this.$store.commit("setGameState", {
        part: "part2",
        type: "finalClock",
        value: time,
      })
      this.$store.commit("setGameState", {
        part: "part2",
        type: "password",
        value: true,
      });
    },
    handleError: function () {},
    setPos() {
      // Stop music
      this.musicStop();

      this.$store.commit("setPosition", "start");
    },
    musicStop() {
      // Stop music
      if (introMusic.playing()) {
        introMusic.once("fade", () => {
          introMusic.stop();
        });
        introMusic.fade(introMusic.volume(), 0, 2000);
      }
      if (loopMusic.playing()) {
        loopMusic.once("fade", () => {
          loopMusic.stop();
        });
        loopMusic.fade(loopMusic.volume(), 0, 2000);
      }
    },
    finalClock() {
      let finalClock = this.$store.state.game.part2.finalClock;
      if (finalClock !== null) {
        console.debug('Store FinalClock');
        convertToClock(finalClock, "time-part-2");
      } else {
        finalClock = this.$store.state.player.savaData.times[1];
        convertToClock(finalClock, "time-part-2");
      }
    },
    async abortPart() {
      await this.musicStop();
      await stopCounter();
      await saveState({
        aborted2: true,
      });
      await this.$store.commit('resetPartTwo');
      this.$store.commit("setPosition", "start");
    },
  },
};
</script>

<style scoped lang="scss">
.ohjus_svg__missile {
  animation: 12s ease-in-out 1s missileAnimation;
  .ohjus_svg__chamber2 {
    animation: 12s ease-in 0s fadeToYellow;
  }

  .ohjus_svg__chamber3, .ohjus_svg__chamber4 {
    fill: #000;
  }
}
</style>
