<template>
  <div class="part3">
    <BlueWrapper
      v-if="hackState === 'starting' || hackState === 'overridden'"
      title="OHJUSTEN HALLINTA"
      :password="gameState.password"
    >
      <template v-slot:password>
        <IntroAnimation
          v-if="hackState === 'starting'"
          title="Tervetuloa ohjusten hallinta järjestelmään vaiheeseen"
          stage="3"
        />
        <div class="password-animation" v-if="hackState === 'starting'">
          <h2 v-if="hackState === 'starting'">SYÖTÄ VARMISTUSKOODI</h2>

          <p v-if="hackState === 'starting'">VAIHE 3/4</p>
          <PasswordChecker
            v-if="hackState === 'starting'"
            v-on:success="hackStart"
            v-on:error="handleError"
            double-check-code="3ARTH"
            password="12201019"
            type="number"
          />
        </div>
        <h2 v-if="hackState === 'overridden'">
          OHJUKSEN UUDELLEEN KALIBROINTI
        </h2>
        <PasswordChecker
          v-if="hackState === 'overridden'"
          class="overridePass"
          password="DBAC"
          v-on:success="handleSuccess"
          v-on:error="handleError"
          :allowLetters="true"
          :override="true"
          type="text"
        />
      </template>
      <template v-slot:animation>
        <div class="top-text">
          <h2>Ohjuksen käynnistys vaihe 3/4</h2>
          <p id="time-part-3" class="time">
            <span class="peli-aika-boksi_svg__days">00</span>
            <span>:</span>
            <span class="peli-aika-boksi_svg__hours">00</span>
            <span>:</span>
            <span class="peli-aika-boksi_svg__minutes">00</span>
            <span>:</span>
            <span class="peli-aika-boksi_svg__seconds">00</span>
          </p>
        </div>
        <Missile />
        <AnimatedBox
          blue-title="SUUTIN & MOOTTORI"
          yellow-title="SYSTEM: OHJUKSEN VAIHE 3/4 SUORITETTU"
        />
        <button @click="setPos" class="btn btn-continue">Jatka</button>
      </template>
      <template v-if="!aborted" v-slot:abort>
        <AbortModal
          v-on:abort="abortPart"
        />
      </template>
    </BlueWrapper>
    <Hack v-if="hackState === 'initialized'"></Hack>
    <div v-bind:class="overrideOverlay">
      <RedStripes/>
      SYSTEM ERROR
    </div>
  </div>
</template>

<script>
import Hack from "@/components/partials/Hack";
import BlueWrapper from "@/components/partials/BlueWrapper";
import PasswordChecker from "@/components/partials/PasswordChecker";
import Missile from "@/assets/ohjus.svg?inline";
import RedStripes from "@/assets/system-error-red-stripes.svg?inline";
import {saveState} from "@/firebase";
import {Timer, countUpFromTime, stopCounter, convertToClock} from "@/timer";
import { Howl } from "howler";
import IntroMusicMp3 from "@/assets/audio/osa3-intro.mp3";
import IntroMusicWebm from "@/assets/audio/osa3-intro.webm";
import LoopMusicMp3 from "@/assets/audio/osa3-loop.mp3";
import LoopMusicWebm from "@/assets/audio/osa3-loop.webm";
import AnimatedBox from "@/components/partials/AnimatedBox";
import IntroAnimation from "@/components/partials/IntroAnimation";
import AbortModal from "@/components/partials/AbortModal";

// setup music
const introMusic = new Howl({
  src: [IntroMusicWebm, IntroMusicMp3],
});
const loopMusic = new Howl({
  src: [LoopMusicWebm, LoopMusicMp3],
  loop: true,
});

export default {
  name: "Part3",
  data() {
    return {
      overrideOverlay: {
        active: false,
        "override-success": true,
      },
      timer: {
        interval: null,
        clock: null,
        offset: null,
        clockStart: null,
      },
    };
  },
  mounted() {
    // Start Timer
    this.timer.clockStart = this.gameState.clockStart;
    this.timer = new Timer();
    this.timer.start(this.gameState.clockStart);
    this.$store.commit("setGameState", {
      part: "part3",
      type: "clockStart",
      value: this.timer.clockStart,
    });

    // Start Counter
    countUpFromTime(this.timer.clockStart, "peli-aika-boksi_svg__Group_1761-2");

    // Play the music
    introMusic.play();
    introMusic.once("end", () => {
      loopMusic.play();
    });

    if (this.gameState.password) {
      this.finalClock();
    }
  },
  updated: function () {
    if (this.gameState.clockStart) {
      countUpFromTime(
        this.timer.clockStart,
        "peli-aika-boksi_svg__Group_1761-2"
      );
    }
    if (this.gameState.password) {
      this.finalClock();
    }
  },
  beforeDestroy() {
    this.musicStop();
    stopCounter();
  },
  components: {
    AbortModal,
    IntroAnimation,
    AnimatedBox,
    PasswordChecker,
    BlueWrapper,
    Hack,
    Missile,
    RedStripes,
  },
  computed: {
    gameState() {
      return this.$store.state.game.part3;
    },
    hackState() {
      return this.$store.state.game.hack.state;
    },
    aborted() {
      return this.$store.state.player.savaData.aborted3;
    },
  },
  methods: {
    hackStart: function () {
      let i = 0;
      const interval = setInterval(() => {
        this.overrideOverlay.active === true
          ? (this.overrideOverlay.active = false)
          : (this.overrideOverlay.active = true);
        i++;
        if (i > 5) {
          stopCounter();
          clearInterval(interval);
          this.$store.commit("setHackState", "initialized");
          this.$store.commit("setHackPosition", "terminal");
        }
      }, 1000);
    },
    handleSuccess: function () {
      stopCounter();
      const time = this.timer.clock;
      this.timer.stop();

      saveState({
        current_section: 4,
        times: { 3: time },
      });
      this.$store.commit("setCurrentSection", 4);
      this.$store.commit("setGameState", {
        part: "part3",
        type: "finalClock",
        value: time,
      })
      this.$store.commit("setGameState", {
        part: "part3",
        type: "password",
        value: true,
      });
    },
    handleError: function () {},
    setPos() {
      // Stop music
      this.musicStop();

      this.$store.commit("setPosition", "start");
    },
    musicStop() {
      // Stop music
      if (introMusic.playing()) {
        introMusic.once("fade", () => {
          introMusic.stop();
        });
        introMusic.fade(introMusic.volume(), 0, 2000);
      }
      if (loopMusic.playing()) {
        loopMusic.once("fade", () => {
          loopMusic.stop();
        });
        loopMusic.fade(loopMusic.volume(), 0, 2000);
      }
    },
    finalClock() {
      let finalClock = this.$store.state.game.part3.finalClock;
      if (finalClock !== null) {
        console.debug('Store FinalClock');
        convertToClock(finalClock, "time-part-3");
      } else {
        finalClock = this.$store.state.player.savaData.times[1];
        convertToClock(finalClock, "time-part-3");
      }
    },
    async abortPart() {
      await this.musicStop();
      await stopCounter();
      await saveState({
        aborted3: true,
      });
      await this.$store.commit('resetPartThree');
      this.$store.commit("setPosition", "start");
    },
  },
};
</script>

<style scoped lang="scss">
.ohjus_svg__missile {
  animation: 12s ease-in-out 1s missileAnimation;
  .ohjus_svg__chamber3 {
    animation: 12s ease-in 0s fadeToYellow;
  }
  .ohjus_svg__chamber4 {
    fill: #000;
  }
}
</style>
