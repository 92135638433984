<template>
  <div class="hack-wrapper">
    <div v-if="hackPosition === 'terminal' && terminal" class="terminal">
      <Terminal></Terminal>
    </div>
    <div v-if="hackPosition === 'vikasietotila'" class="vikasieto" id="vikasieto">
      <Vikasieto></Vikasieto>
    </div>
    <div class="matrix">
      <VueMatrixRaindrop
          textContent="田由甲申甴电甶男甸甹町画甼甽甾甿畀畁畂畃畄畅畆畇畈畉畊畋界畍畎畏畐畑"
          :canvasWidth="5000"
          :canvasHeight="2160"
          :speed="2"
      >
      </VueMatrixRaindrop>
    </div>
  </div>
</template>

<script>
import Terminal from "@/components/partials/Terminal";
import VueMatrixRaindrop from 'vue-matrix-digit-rain';
import Vikasieto from "@/components/partials/Vikasieto";
export default {
name: "Hack",
  data() {
    return {
      terminal: false,
      height: ''
    }
  },
  components: {Vikasieto, Terminal, VueMatrixRaindrop},
  computed: {
    hackPosition() {
      return this.$store.state.game.hack.position
    }
  },
  methods: {
    showTerminal() {
      this.terminal = true
    }
  },
  mounted() {
    window.scrollTo(0,0);
    setTimeout(this.showTerminal, 2000);
  }
}
</script>

<style scoped lang="scss">

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

.hack-wrapper {
  min-width: 0;
  .matrix {
    max-height: 100vh;
    overflow: hidden;
    #vue-matrix-raindrop {
      min-height: 100vh;
    }
  }
  .vikasieto + .matrix {
    @include media-breakpoint-down(sm) {
      min-height: 1750px;
    }
  }
  .terminal, .vikasieto {
    position: absolute;
    top:  0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: rgba(0,0,0,0.5);
    .vue-terminal {
      max-width: 960px;
      max-height: 530px;
      height: 100%;
    }
  }
  .vikasieto {
    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }
}
</style>