import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD8Qv3fAp4DYm20lPH1ZPLohkyviZG403A",
  authDomain: "exite-33c4f.firebaseapp.com",
  databaseURL: "https://exite-33c4f.firebaseio.com",
  projectId: "exite-33c4f",
  storageBucket: "exite-33c4f.appspot.com",
  messagingSenderId: "333668628654",
  appId: "1:333668628654:web:802db2f887d780968bd53f",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// utils
const db = firebase.firestore();
const auth = firebase.auth();
let functions;

// Always run functions/emulate before developing.

functions = firebase.app().functions("europe-west1");

const authenticateWithToken = async (token) => {
  try {
    await auth.signInWithCustomToken(token);
  } catch (err) {
    console.error(err);
  }
};

const logOut = async () => {
  try {
    await auth.signOut();
  } catch (err) {
    console.error(err);
  }
};

/**
 * Pass a object with the following structure
 * { name: String, code: String }
 * @type {firebase.functions.HttpsCallable}
 */
const checkCode = functions.httpsCallable("checkCode");
/**
 * Pass a object with the following structure
 * { current_section: number, times: { 1: number, 2... } }
 * @type {firebase.functions.HttpsCallable}
 */
const saveState = functions.httpsCallable("saveState");

/**
 * returns an object in the form of this:
 * { current_section: number, times: { 1: number, 2... } }
 * @type {firebase.functions.HttpsCallable}
 */
const loadState = functions.httpsCallable("loadState");

/**
 * returns an array of stats objects in the form of this:
 * { current_section: number, times: { 1: number, 2... } }
 * @type {firebase.functions.HttpsCallable}
 */
const getStats = functions.httpsCallable("getStats");

export {
  db,
  auth,
  checkCode,
  saveState,
  loadState,
  getStats,
  authenticateWithToken,
  logOut,
};
