<template>
  <div class="password-checker">
    <div class="d-flex flex-row justify-content-center">
      <div class="col" v-for="(item, index) in passwordValues" :key="index">
        <div v-if="override" :class="'ball ' + ballColors[index]"></div>
        <input
          :key="index"
          class="passcode-input"
          :ref="`pass-${index}`"
          :id="index"
          :type="type"
          @input="passFieldHandler($event)"
          @focus="alwaysLast($event)"
          @keyup="backSpaceHandler($event)"
        />
      </div>
    </div>
    <button class="btn confirm-btn">
      <ConfirmBtn
        :class="{ error: error }"
        @click="validatePassword"
      ></ConfirmBtn>
    </button>
    <div v-if="doubleCheckCode" class="doublecheck-container">
      <p class="doublecheck">Tuplavarmennuskoodi: {{ doubleCheckCode }}</p>
      <div class="info-container">
        <img
          class="info-icon"
          @mouseleave="showInfo = false"
          :src="InfoIcon"
          alt=""
        />
        <span
          :class="{ show: showInfo }"
          class="info-text"
          @mouseleave="showInfo = false"
          >Lähetä varmennuskoodi ASTR:n johtajalle</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { numbers } from "@/utils";
import { letters } from "@/utils";
import ConfirmBtn from "@/assets/Vahvista-nappi.svg?inline";
import InfoIcon from "@/assets/Info-pallo.svg";

export default {
  name: "PasswordChecker",
  props: {
    password: String,
    doubleCheckCode: String,
    allowLetters: Boolean,
    override: Boolean,
    type: String,
    shouldShow: {
      type: Boolean,
      default: false,
    },
  },
  components: { ConfirmBtn },
  methods: {
    passFieldHandler(event) {
      const key = event.data;
      const target = event.target;
      const currentNumber = parseInt(target.id);
      if (this.allowLetters) {
        if (!letters.includes(key)) {
          target.value = "";
          event.preventDefault();
          return;
        }
      } else if (!numbers.includes(key)) {
        target.value = "";
        event.preventDefault();
        return;
      }
      if (target.value.length >= 1) {
        let inputted = 0;
        if (this.allowLetters && target.value.substr(-1, 1).match(/[A-D]/i)) {
          inputted = target.value.substr(-1, 1).toUpperCase();
        } else if (!isNaN(parseInt(target.value.substr(-1, 1))))
          inputted = target.value.substr(-1, 1);
        target.value = inputted;
        this.passwordValues[currentNumber] = target.value;
        if (currentNumber + 1 !== this.passwordValues.length)
          this.$refs[`pass-${currentNumber + 1}`][0].focus();
      }
    },
    backSpaceHandler(event) {
      const key = event.key;
      const target = event.target;
      const currentNumber = parseInt(target.id);
      if (key === "Backspace") {
        target.value = "";
        if (currentNumber - 1 >= 0) {
          this.$refs[`pass-${currentNumber - 1}`][0].focus();
        }
      }
    },
    alwaysLast(event) {
      const target = event.target;
      target.value = "";
    },
    validatePassword() {
      if (this.password === this.passwordValues.join("")) {
        this.$emit("success");
      } else {
        Object.values(this.$refs).forEach((value) => {
          value[0].value = "";
        });
        this.error = true;
        let self = this;
        setTimeout(function () {
          self.error = false;
        }, 1000);
        this.$emit("error");
      }
    },
  },
  data() {
    return {
      passwordValues: new Array(this.password.length),
      ballColors: ["purple", "yellow", "blue", "red"],
      error: false,
      InfoIcon,
      showInfo: false,
    };
  },
  mounted() {
    this.showInfo = this.shouldShow;
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/variables";
@import "~bootstrap";

.password-checker {
  position: relative;
}
.passcode-input {
  width: 24px;
  margin: 0 5px;
  height: 37px;
  font-size: 18px;
  text-align: center;
  border: 2px solid #20f7fe;
  background: black;
  color: #fca502;
  &:focus {
    color: #fca502;
  }
  @include media-breakpoint-up(md) {
    margin: 0 10px;
    width: 31px;
    height: 49px;
    font-size: 20px;
  }
}
.overridePass {
  .passcode-input {
    border: 2px solid $yellow;
    color: $cyan;
    &:focus {
      color: $cyan;
    }
  }
}
.ball {
  width: 50px;
  height: 50px;
  background: black;
  border-radius: 90%;
  margin: 0 0.5rem 1rem 0.5rem;
  @include media-breakpoint-up(md) {
    width: 75px;
    height: 75px;
  }
  &.purple {
    background: #7a1597;
  }
  &.yellow {
    background: #fca502;
  }
  &.blue {
    background: #00a0c9;
  }
  &.red {
    background: #c90000;
  }
}
.doublecheck-container {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-up(md) {
    margin-top: 0.5rem;
  }
  .info-icon {
    height: 32px;
  }
}
.doublecheck {
  color: $yellow;
  font-size: 18px;
  letter-spacing: 0.1em;
  margin-top: 0;
  margin-bottom: 0;
}
.info-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  .info-text {
    visibility: hidden;
    width: 160px;
    background-color: black;
    text-align: center;
    color: $yellow;
    padding: 5px 0;
    border-radius: 6px;
    letter-spacing: 0.1em;

    position: absolute;
    z-index: 1;
    bottom: 125%;
    right: 0;
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      right: 10px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
    &.show {
      visibility: visible;
    }
  }
  &:hover,
  &:active {
    .info-text {
      visibility: visible;
    }
  }
}
.confirm-btn {
  max-width: 151px;
  padding: 0;
  margin-top: 1rem;
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 296px;
  }
  .error {
    .Vahvista-nappi_svg__Path_490 {
      fill: $red;
    }
    .Vahvista-nappi_svg__Path_491 {
      fill: $red;
    }
    .Vahvista-nappi_svg__Path_498 {
      fill: $red;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
