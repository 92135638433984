<template>
  <div class="abort-modal">
    <button class="btn btn-abort" data-toggle="modal" data-target="#abortModal">Keskeytä osio ja aloita osio alusta
    </button>
    <!-- Modal -->
    <div class="modal fade" id="abortModal" tabindex="-1" role="dialog"
         aria-labelledby="abortModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            Oletko varma että haluat keskeyttää pelin? Tämä toiminto on tarkoitettu käytettäväksi ainoastaan
            ongelmatilanteissa
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" data-toggle="modal"
                    data-target="#abortModal">Ei
            </button>
            <button @click="abortEvent" type="button" data-dismiss="modal" data-toggle="modal" data-target="#abortModal" class="btn btn-primary">Kyllä</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "AbortModal",
  props: {
  },
  methods: {
    abortEvent() {
      this.$emit('abort');
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/scss/variables";
.modal-content {
  background: black;
  color: $yellow;
  letter-spacing: 0.1em;
  text-align: center;
  .modal-footer {
    justify-content: space-between;
  }
  .btn {
    min-width: 100px;
    letter-spacing: 0.1em;
  }
  .btn-primary {
    background: $cyan;
    color: #000;
  }
}
</style>