<template>
  <div>
    <div class="offset-md-2 col-md-8">
      <div class="container text-center text-md-left">
        <div class="row">
          <div class="col-12">
            <p class="stats-title">Nimi</p>
            <p class="stat">{{ teamName }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p class="stats-title">Kokonaisaika</p>
            <p id="time-total" class="time">
              <span class="peli-aika-boksi_svg__days">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__hours">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__minutes">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__seconds">00</span>
            </p>
          </div>
          <div class="col-md-8">
            <p class="stats-title">Muista suoriutui tehtävästä nopeammin</p>
            <p class="stat">{{ statistics.combined }} %</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p class="stats-title">Osa 1</p>
            <p id="time-part-1" class="time">
              <span class="peli-aika-boksi_svg__days">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__hours">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__minutes">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__seconds">00</span>
            </p>
          </div>
          <div class="col-md-8">
            <p class="stats-title">Muista suoriutui tehtävästä nopeammin</p>
            <p class="stat">{{ statistics.section1 }} %</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p class="stats-title">Osa 2</p>
            <p id="time-part-2" class="time">
              <span class="peli-aika-boksi_svg__days">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__hours">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__minutes">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__seconds">00</span>
            </p>
          </div>
          <div class="col-md-8">
            <p class="stats-title">Muista suoriutui tehtävästä nopeammin</p>
            <p class="stat">{{ statistics.section2 }} %</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p class="stats-title">Osa 3</p>
            <p id="time-part-3" class="time">
              <span class="peli-aika-boksi_svg__days">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__hours">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__minutes">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__seconds">00</span>
            </p>
          </div>
          <div class="col-md-8">
            <p class="stats-title">Muista suoriutui tehtävästä nopeammin</p>
            <p class="stat">{{ statistics.section3 }}%</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <p class="stats-title">Osa 4</p>
            <p id="time-part-4" class="time">
              <span class="peli-aika-boksi_svg__days">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__hours">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__minutes">00</span>
              <span>:</span>
              <span class="peli-aika-boksi_svg__seconds">00</span>
            </p>
          </div>
          <div class="col-md-8">
            <p class="stats-title">Muista suoriutui tehtävästä nopeammin</p>
            <p class="stat">{{ statistics.section4 }}%</p>
          </div>
        </div>
        <div class="row misc-stats">
          <p class="stats-title">Maapallo pelastettu:</p>
          <p class="stat">{{ statistics.saved }} kertaa</p>
          <p class="stats-title">Maapallo tuhoutunut:</p>
          <p class="stat">{{ statistics.destroyed }} kertaa</p>
          <p class="stats-title">Kuolonuhreja operaatiossa keskimäärin:</p>
          <p class="stat">{{ statistics.casualties }} kpl</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {convertToClock} from "@/timer";
import {getStats} from "@/firebase";
import {Statistics} from "@/results";

export default {
  name: "Statistics",
  props: {
    results: Object,
  },
  data() {
    return {
      statsData: Array,
      statistics: Object,
    }
  },
  computed: {
    teamName() {
      return this.$store.state.player.userName;
    }
  },
  methods: {
    async loadStats() {
      const stats = await getStats();
      if (!stats.data.error && stats.data.stats) {
        this.statsData = stats.data.stats;
      } else {
        console.error(stats.data.message);
      }
      this.setupStats();
    },
    setupStats() {
      this.statistics = new Statistics();
      this.statistics.stats(this.statsData, this.$store.state.player.savaData);
    }
  },
  mounted() {
    this.loadStats();
    convertToClock(this.$store.state.player.savaData.times.combined, 'time-total');
    convertToClock(this.$store.state.player.savaData.times[1], 'time-part-1');
    convertToClock(this.$store.state.player.savaData.times[2], 'time-part-2');
    convertToClock(this.$store.state.player.savaData.times[3], 'time-part-3');
    convertToClock(this.$store.state.player.savaData.times[4], 'time-part-4');
  }
}
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

.stats-title {
  font-size: 25px;
  color: #707070;
  margin-bottom: 12px;
  margin-top: 25px;
  letter-spacing: 0.1em;
  line-height: 1;
}

.stat, .time {
  font-size: 30px;
  margin-top: 0;
  color: #fff;
  letter-spacing: 0.1em;
  margin-bottom: 25px;
  line-height: 1;
}

.row {
  border-bottom: 2px solid #707070;

  &:last-of-type {
    margin-bottom: 75px;
  }
}

.misc-stats {
  .stat {
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: 25px;
    }
  }
}
</style>